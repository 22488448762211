.container.button {
  background-color: rgba(0, 0, 0, 0.4);
  font-family: SpiegelSB;
  transition: all 0.2s;
}

.container.button:hover {
  border-color: #fff;
  color: #fff;
  /* padding-left: calc(
    var(--button-hover-padding-ratio) * var(--button-padding-horizontal)
  );
  padding-right: calc(
    var(--button-hover-padding-ratio) * var(--button-padding-horizontal)
  );
  padding-top: calc(
    var(--button-hover-padding-ratio) * var(--button-padding-vertical)
  );
  padding-bottom: calc(
    var(--button-hover-padding-ratio) * var(--button-padding-vertical)
  ); */
}
