.guessHistory.container {
  --guess-size: calc(2vw * (var(--main-width) / 80));
  --guess-margin: calc(var(--guess-size) * 0.08);

  max-width: calc(20vw * (var(--main-width) / 80));
  overflow: scroll;

  padding: calc(1vw * (var(--main-width) / 80));

  background-color: var(--right-side-menu-background);
  border-radius: calc(0.5vw * (var(--main-width) / 80));
  border: calc(0.1vw * (var(--main-width) / 80)) solid #fff;

  transition: opacity 0.2s;
}

.guesses span {
  font-size: calc(1vw * (var(--main-width) / 80));
}

.guessHistory h1 {
  color: #fff;
  font-weight: bold;
  font-size: calc(2vw * (var(--main-width) / 80));

  text-align: center;

  margin-top: 0;
  margin-bottom: calc(1vw * (var(--main-width) / 80));
}

.guesses.container {
  min-height: calc(var(--guess-size) * 8 + var(--guess-margin) * 7);
}

.guesses.container > *:not(:last-child) {
  margin-right: var(--guess-margin);
}

.guess.container > *:not(:last-child) {
  margin-bottom: var(--guess-margin);
}

.boardGuess {
  width: var(--guess-size);
  height: var(--guess-size);

  /* border-radius: calc(var(--guess-size) * 0.1); */
}

.boardGuess.correct {
  background-color: var(--correct-color);
}

.boardGuess.incorrect {
  background-color: var(--incorrect-color);
}
