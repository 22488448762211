.container.error {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  color: #fff;
}

.container.error a {
  cursor: pointer;
  color: #94f8e0;
}
