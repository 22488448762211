.animation.reset {
  /* animation-duration: 1s;
  animation-name: reset; */
}

.animation {
  display: flex;
}

@keyframes reset {
  from {
    opacity: 100%;
  }

  50% {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}
