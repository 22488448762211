:root {
  --unit-image-size: calc(3vw * (var(--main-width) / 80));
  --unit-image-border: calc(0.22vw * (var(--main-width) / 80));
}

span {
  font-size: calc(0.9vw * (var(--main-width) / 80));
  color: #fff;
}

/* .container.augments,
.container.traits,
.container.units {
  background-color: #6b6b6baa;
} */

.container.player {
  margin-left: calc(1vw * (var(--main-width) / 80));
  align-items: start;
}

.container.player .playerName {
  margin-top: calc(0.5vw * (var(--main-width) / 80));
  color: #fff;
  font-size: calc(1.2vw * (var(--main-width) / 80));
}

.container.player .playerLevelContainer {
  position: absolute;
  right: calc(-0.5vw * (var(--main-width) / 80));
  bottom: calc(-0.5vw * (var(--main-width) / 80));

  width: calc(1.4vw * (var(--main-width) / 80));
  height: calc(1.4vw * (var(--main-width) / 80));

  background-color: #000;
  border-radius: 50%;
  border: calc(0.15vw * (var(--main-width) / 80)) solid;

  font-size: calc(0.9vw * (var(--main-width) / 80));
  font-weight: bold;
  color: #fff;
}

.container.playerImage {
  width: calc(4vw * (var(--main-width) / 80));
  height: calc(4vw * (var(--main-width) / 80));
  border-width: calc(0.2vw * (var(--main-width) / 80));
  border-style: solid;
  border-radius: 50%;
  position: relative;
}

.container.player img {
  width: calc(4vw * (var(--main-width) / 80));
  height: calc(4vw * (var(--main-width) / 80));
  border-radius: 50%;
  object-fit: cover;
}

.container.augments {
  margin-top: calc(0.5vw * (var(--main-width) / 80));
}

.container.augment {
  border: calc(0.1vw * (var(--main-width) / 80)) solid #5f5f5f;
  margin-right: calc(0.2vw * (var(--main-width) / 80));
}

.augment img {
  width: calc(2.5vw * (var(--main-width) / 80));
}

.container.traits {
  flex-wrap: wrap;
  overflow: scroll;
  justify-content: left;
  align-items: center;
  max-width: calc(14vw * (var(--main-width) / 80));
  max-height: calc(8vw * (var(--main-width) / 80));
}

.traits .image {
  filter: brightness(0%);
}

.container.trait {
  align-items: center;
  justify-content: left;
}

.trait span {
  white-space: nowrap;
  margin-top: calc(0.2vw * (var(--main-width) / 80));
  font-size: calc(0.8vw * (var(--main-width) / 80));
}

.traits .traitImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(calc(2.27vw * (var(--main-width) / 80)) * 0.8);
  height: calc(calc(2.625vw * (var(--main-width) / 80)) * 0.8);
  background-size: cover;
  margin-right: calc(0.1vw * (var(--main-width) / 80));
}

.traitImageContainer img {
  width: 60%;
}

.container.units {
  position: absolute;
  right: calc(1vw * (var(--main-width) / 80));
}

.container.unit {
  position: relative;
  margin-right: calc(0.5vw * (var(--main-width) / 80));
}

.container.items {
  position: absolute;
  bottom: calc((var(--unit-image-size) + var(--unit-image-border) * 2) / -3);
}

.item img {
  width: calc((var(--unit-image-size) + var(--unit-image-border) * 2) / 3);
}

.unit img.unitImage {
  border-radius: 10%;
  width: var(--unit-image-size);
}
