@font-face {
  font-family: "BeaufortM";
  src: local("Beaufort_Medium"),
    url("./fonts/Beaufort_Medium.ttf") format("truetype");
}

@font-face {
  font-family: "BeaufortL";
  src: local("Beaufort_Light"),
    url("./fonts/Beaufort_Light.ttf") format("truetype");
}

@font-face {
  font-family: "BeaufortH";
  src: local("Beaufort_Heavy"),
    url("./fonts/Beaufort_Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "SpiegelSB";
  src: local("Spiegel_TT_SemiBold"),
    url("./fonts/Spiegel_TT_SemiBold.ttf") format("truetype");
}

body {
  background-color: #1e1e21;
}

.container {
  display: flex;
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}

.centered {
  align-items: center;
  justify-content: center;
}
