.hoverTitle {
  font-weight: bold;
  font-size: 1vw;
}

.hoverDescription {
  font-size: 0.82vw;
}

.hoverContainer {
  position: relative;
}
