.shareScore {
  color: #fff;
  background-color: var(--right-side-menu-background);

  min-width: calc(25vw * (var(--main-width) / 80));
  padding: calc(1vw * (var(--main-width) / 80));
  border-radius: calc(0.5vw * (var(--main-width) / 80));
  border: calc(0.1vw * (var(--main-width) / 80)) solid #fff;

  font-size: calc(1.2vw * (var(--main-width) / 80));
}

.sharePreview {
  white-space: pre-line;
}

.shareButtons {
  margin-top: calc(1vw * (var(--main-width) / 80));
}

.shareButtons > *:not(:last-child) {
  margin-right: calc(0.5vw * (var(--main-width) / 80));
}
