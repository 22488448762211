.container.screen {
  z-index: -2;

  width: 100vw;
  min-height: 100vh;

  background-size: cover;
  background-color: rgb(122, 122, 122);
  background-blend-mode: multiply;
}
