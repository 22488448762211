:root {
  --board-height: calc(8vw * (var(--main-width) / 80));
  --board-container-border-radius: calc(1vw * (var(--main-width) / 80));
  --board-container-left-offset: calc(4vw * (var(--main-width) / 80));

  --correct-color: #64aa7b;
  --incorrect-color: #b65f5f;
  --right-side-menu-background: rgba(255, 255, 255, 0.1);
}

#gameContainer {
  z-index: 0;

  display: flex;
  width: 100vw;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#gameContainer p {
  color: #fff;
  font-size: calc(1.5vw * (var(--main-width) / 80));
  font-family: SpiegelSB;
  margin-bottom: 0;
}

#boardsContainer {
  display: flex;
  height: calc(var(--board-height) * 8);
  margin-top: calc(2vw * (var(--main-width) / 80));
  position: relative;
  margin-left: calc(var(--board-container-left-offset));
}

.boardPlacementContainer {
  display: flex;
  position: absolute;
  background-color: #00000088;
  left: calc(-1 * var(--board-container-left-offset));
  height: var(--board-height);
  width: calc(100% + var(--board-container-left-offset));
  z-index: -1;
  align-items: center;
  border-radius: var(--board-container-border-radius);

  transition: background-color 0.2s;
}

.boardPlacementContainer.correct {
  background-color: var(--correct-color);
}

.boardPlacementContainer.incorrect {
  background-color: var(--incorrect-color);
}

.boardPlacementNumber {
  color: #fff;
  font-size: calc(2vw * (var(--main-width) / 80));
  font-weight: bold;
  text-align: center;
  padding-left: calc(var(--board-container-left-offset) * 0.4);
}

.container.rightSide {
  z-index: -1;
  position: absolute;
  margin-left: calc(1vw * (var(--main-width) / 80));

  left: 100%;
}

.container.rightSide.mobile {
  margin-top: calc(1vw * (var(--main-width) / 80));
}

.container.rightSide.desktop {
  margin-left: calc(1vw * (var(--main-width) / 80));
}
