:root {
  --slide-animation-duration: 0.5s;
}

.boardContainer {
  display: flex;
  transition: background-color 0.35s ease;
  cursor: pointer;
  align-items: center;
  position: relative;

  height: var(--board-height);
}

.container.board {
  border-top-right-radius: var(--board-container-border-radius);
  border-bottom-right-radius: var(--board-container-border-radius);

  width: calc(65vw * (var(--main-width) / 80));
  height: var(--board-height);

  align-items: center;
  justify-content: start;

  /* border-top: 1px solid rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgba(0, 0, 0, 0.5); */

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;

  position: relative;
  left: 0vw;
  transition: left var(--slide-animation-duration);

  transition: all 0.2s;
}

.container.traitsAugments {
  margin-left: calc(2vw * (var(--main-width) / 80));
  align-items: start;
}

.container.board.selected {
  left: calc(5vw * (var(--main-width) / 80));
}

.container.board.slideleft {
  animation-duration: var(--slide-animation-duration);
  animation-name: slide-left;
}

.augmentsUnitsContainer {
  justify-content: flex-start;
}

.answer {
  color: #fff;
  font-size: 2vw;
  font-weight: bold;
  position: fixed;
  bottom: 20vh;

  animation-duration: var(--slide-animation-duration);
  animation-name: slide-in;
}

@keyframes slide-in {
  from {
    bottom: -10vh;
  }

  to {
    bottom: 20vh;
  }
}

@keyframes slide-right {
  from {
    left: 0vw;
  }

  to {
    left: calc(5vw * (var(--main-width) / 80));
  }
}

@keyframes slide-left {
  from {
    left: calc(5vw * (var(--main-width) / 80));
  }

  to {
    left: 0vw;
  }
}
