.homeMenuContainer {
  /* border: 0.2vw solid #fff; */
  border-radius: calc(1vw * (var(--main-width) / 80));

  padding: calc(1vw * (var(--main-width) / 80));
  max-width: calc(40vw * (var(--main-width) / 80));
}

.container.home {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.container.home p {
  color: #fff;
  font-size: calc(2vw * (var(--main-width) / 80));
  font-family: SpiegelSB;
}

.homeTitle > *:not(:last-child) {
  margin-right: calc(1vw * (var(--main-width) / 80));
}

.homeTitle {
  margin-left: calc(2vw * (var(--main-width) / 80));
  margin-right: calc(2vw * (var(--main-width) / 80));
}

.homeTitle span {
  font-size: calc(8vw * (var(--main-width) / 80));
}

.homeTitle span.or {
  font-family: BeaufortM;
  font-size: calc(5vw * (var(--main-width) / 80));
}

.homeTitle span.first {
  font-family: BeaufortH;
  font-weight: bold;
  color: #ffe358;
}

.homeTitle span.eighth {
  font-family: BeaufortH;
  font-weight: bold;
  color: #c1d4e8;
}

.container.homeTitleContainer img {
  height: calc(12vw * (var(--main-width) / 80));
}

.homeMenuContainer > *:not(:last-child) {
  margin-bottom: calc(1.5vw * (var(--main-width) / 80));
}

.nextGameMenu.container {
  position: fixed;
  bottom: 10vh;
  left: 50%;
  transform: translate(-50%, 0%);
}
